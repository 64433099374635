<script setup>
import { ref } from 'vue';
// Props
const props = defineProps({
  value: {
    type: [String, Number, Object], // Can handle string or number values
    required: true,
  },
  options: {
    type: Array,
    required: true,
    default: () => [], // Array of { label: 'Option Name', value: 'optionValue' }
  },
  filter: {
    type: Object,
    required: true,
  },
  filtersStore: {
    type: Object,
    required: true,
  }
});

// Emits
const emit = defineEmits(['update']);

//
const router = useRouter();

// Current selected value
const selected = ref(props.value);

// Select an option
const selectOption = (option) => {
  selected.value = option;
  emit('update', selected.value);

  router.push({
    query: {
      category: selected.value.slug,
    }
  })

  props.filtersStore.updateCategory(option);
  props.filtersStore.fetchFilters(selected.value.slug);
};
selectOption(selected.value)
</script>
<template>
  <div class="border-b my-3">
    <h3 class="text-lg font-medium text-indigo-900 leading-normal">{{ filter?.name || "Category" }}</h3>
    <div class="w-full flex gap-2 snap-x overflow-x-auto my-3">
      <div class="snap-start  shrink-0 relative" v-for="item in options">
        <button @click="selectOption(item)" class="rounded-2xl p-3 block w-24"
          :class="{ [item.slug == selected.slug ? 'active-type' : 'bg-white']: true }">

          <div class="flex flex-col justify-center items-center">
            <i :class="item.icon" class="h-6 text-indigo-900" v-if="item.icon"></i>
            <h4 class="text-sm" :class="{ [item.icon ? 'mt-3' : '']: true }">{{
              item.name }}</h4>
          </div>
        </button>
      </div>
    </div>
  </div>

</template>